import React from "react"
import "../styles/layout.scss"
import Header from "./header"
import Footer from "./footer"

export default ({ path, children }) => <>
	<Header path={path} />

	{children}

	<Footer />
</>