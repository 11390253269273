import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { navigate } from '@reach/router';

function ContactForm() {
	const [validated, setValidated] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [errored, setErrored] = useState(false);
	const [values, setValues] = useState();
	

	const handleInputChange = (e) => setValues({ ...values, [e.target.name]: e.target.value });

	const handleSubmit = (event) => {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.stopPropagation();
		} else {
			// submit
			// todo - recaptcha
			console.log(JSON.stringify(values));
			fetch("https://api.ginamcdonaldlaw.com", {
				method: 'POST',
				body: JSON.stringify(values),
				headers: {
					'Content-Type': 'application/json'
				}
			})
				.then(res => res.json())
				.then(
					(result) => {
						setSubmitted(true);
						// navigate('/thank-you');
						// todo - can redirect or just show the thanks
					},
					// Note: it's important to handle errors here
					// instead of a catch() block so that we don't swallow
					// exceptions from actual bugs in components.
					(error) => {
						setErrored(true);
						// 			this.setState({
						// 				isLoaded: true,
						// 				error
						// 			});
					}
				)
		}

		setValidated(true);
	};

	if(errored){
		return <Form.Text>
			The contact system in unavailable right now. Please call us to schedule your consultation.
		</Form.Text>
	}

	return (
		<Form noValidate validated={validated} onSubmit={handleSubmit} action="thank-you.html" style={{height: '100%'}}>
			<Form.Group md="4" controlId="userName">
				<Form.Label>Name</Form.Label>
				<Form.Control
					required
					name="name"
					type="text"
					placeholder="Enter your full name"
					onChange={handleInputChange}
				/>
				<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
			</Form.Group>
			<Form.Group controlId="userEmail">
				<Form.Label>Email address</Form.Label>
				<Form.Control required type="email" name="email" placeholder="Enter your email address" onChange={handleInputChange} />
				<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
			</Form.Group>
			<Form.Group controlId="userPhone">
				<Form.Label>Phone</Form.Label>
				<Form.Control required type="text" name="phone" placeholder="Enter your primary phone number" onChange={handleInputChange} />
				<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
			</Form.Group>
			<Form.Group controlId="userMessage">
				<Form.Label>Message <span className="text-muted small">* Optional</span></Form.Label>
				<Form.Control as="textarea" name="message" rows="3" onChange={handleInputChange} />
			</Form.Group>

			{submitted && <Form.Text>
				Thank you for contacting us. We will be in touch soon!
    		</Form.Text>}
			<Button type="submit" disabled={submitted || errored}>Send Message</Button>
		</Form>
	);
}

export default ContactForm