import { graphql, Link, StaticQuery } from "gatsby"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faLinkedin, faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { Container, Row, Col, ResponsiveEmbed, Form, Button, Badge } from "react-bootstrap"
import questionPattern from "../images/question-pattern.png"
import ContactForm from "./contactForm"

export default ({ data }) => <footer className="text-light" style={{ background: "rgba(28,28,28)" }}>
	<Container fluid className="px-0">
		<Row noGutters>
			<Col><ResponsiveEmbed aspectRatio="1by1"><iframe src="https://maps.google.com/maps?q=gina%20mcdonald%20law&t=&z=15&ie=UTF8&iwloc=&output=embed" title="Map of Valleydale office"></iframe></ResponsiveEmbed></Col>
			<Col className="bg-primary text-center">
				<div className="d-flex justify-content-center align-items-center h-100">
					<div className="m-5">
					<header>
						<h3 className="text-uppercase"><Badge variant="secondary">Stay Connected</Badge>
							<br /><br/>
							<span className="text-secondary">Get in</span> touch
						</h3>
					</header>
					<div>
						<p className="mb-4">We know the hardest step can be making that first call for help. For over 20 years in Alabama, we have helped others just like you!</p>
					</div>
					<address className="text-uppercase text-secondary">

						<p>Phone number:<br/> <span className="text-white">+1 205 982 3325</span></p>
						<p>Address:<br/> <span className="text-white">2057 VALLEYDALE ROAD, SUITE 202<br />
								BIRMINGHAM, AL 35244</span></p>
					</address>

					<ul className="list-inline mb-0">
						<li className="list-inline-item g-mx-5">
						<a className="btn btn-secondary" href="https://www.facebook.com/pages/Gina-H-McDonald-Associates-LLC/236883119983"><FontAwesomeIcon icon={faFacebookF} /></a>
							<a className="u-icon-v3 u-icon-size--sm g-font-size-default g-color-primary g-bg-white g-bg-black-opacity-0_9--hover" href="//www.facebook.com/pages/Gina-H-McDonald-Associates-LLC/236883119983">
								<i className="fa fa-facebook"></i>
							</a>
						</li>
						<li className="list-inline-item g-mx-5">
						<a className="btn btn-secondary" href="https://twitter.com/bye_bye_debt"><FontAwesomeIcon icon={faTwitter} /></a>
							<a className="u-icon-v3 u-icon-size--sm g-font-size-default g-color-primary g-bg-white g-bg-black-opacity-0_9--hover" href="//www.linkedin.com/in/ginahmcdonald">
								<i className="fa fa-linkedin"></i>
							</a>
						</li>
						<li className="list-inline-item g-mx-5">
						<a className="btn btn-secondary" href="https://instagram.com/BANKRUPTCY_101"><FontAwesomeIcon icon={faInstagram} /></a>
							<a className="u-icon-v3 u-icon-size--sm g-font-size-default g-color-primary g-bg-white g-bg-black-opacity-0_9--hover" href="//twitter.com/stopcreditor">
								<i className="fa fa-twitter"></i>
							</a>
						</li>

					</ul>
					</div>
				</div>
			</Col>
			<Col className="align-self-center" style={{ background: `url('${questionPattern}')` }}>
				<div className="m-5">
					<h2 className="text-white text-center text-uppercase g-font-weight-700 g-mb-50"> Have a <span className="text-primary">Question?</span></h2>
					<ContactForm />
				</div>
			</Col>
		</Row>
	</Container>

	<Container>
		<Row className="py-5">
			<h3 className="pr-5">Locations</h3>
			<Col>
				<h3 className="text-secondary">Birmingham</h3>
				<address>2057 Valleydale Road, Suite 202<br />Birmingham, AL 35244</address>

				Phone: 205 982 3325<br />
				Fax: 205 982 7070
			</Col>
			<Col>
				<h3 className="text-secondary">Jasper</h3>
				<address>1810 3rd Avenue, Suite 102<br />
Jasper AL 35501</address>Phone: 205 522 7727
				</Col>
			<Col>
				<h3 className="text-secondary">Oneonta</h3>
				<address>305 1st Avenue East<br />Oneonta AL 35121</address>Phone: 205 625 3325</Col>
		</Row>
		<hr />
		<Row className="py-5">
			<Col><StaticQuery
				query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
				render={data => (
					<div className="text-center">{new Date().getFullYear()} All right reserved. {data.site.siteMetadata.title}</div>
				)}
			/></Col>
		</Row>
	</Container>
</footer>

// export const query = graphql`
//   query MyQuery {
//   site {
//     siteMetadata {
//       title
//     }
//   }
// }
// `