import React from "react"
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap'
import HeaderBanner from "./headers/banner"
import { Link } from "gatsby"

export default () => <section>
	<HeaderBanner />
	<Navbar expand="lg" variant="light" bg="white" className="py-4">
		<Container>
			<Navbar.Brand as={Link} to="/">Gina H. McDonald <span className="d-none d-sm-block">&amp; Associates, <small className="text-muted">L.L.C.</small></span></Navbar.Brand>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" />
			<Navbar.Collapse id="responsive-navbar-nav">
				<Nav className="ml-auto">
					<Nav.Link as={Link} to="/" activeClassName="active">Home</Nav.Link>
					<NavDropdown title="Practice Areas" id="practiceAreas">
						<NavDropdown.Item as={Link} to="/practice-areas/bankruptcy-law" activeClassName="active">Bankruptcy Law</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/practice-areas/personal-injury-law" activeClassName="active">Personal Injury Law</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/practice-areas/family-law" activeClassName="active">Family Law</NavDropdown.Item>
					</NavDropdown>
					<NavDropdown title="Attorneys" id="attorneys" as={Link} to="/attorneys" activeClassName="active" partiallyActive={true}>
						<NavDropdown.Item as={Link} to="/attorneys/gina-mcdonald" activeClassName="active">Gina H. McDonald</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/attorneys/heather-bellew" activeClassName="active">Heather Bellew</NavDropdown.Item>
					</NavDropdown>
				</Nav>
			</Navbar.Collapse>
		</Container>
	</Navbar>
</section>