import React from "react"
import { Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faPhoneAlt, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

export default () => <section className="bg-dark text-light" style={{lineHeight: '2.5'}}>
	<Container>
		<Row noGutters='true'>
			<Col className="d-none d-sm-block">
				<a className="mr-1 btn btn-outline-primary border-0" href="https://www.facebook.com/pages/Gina-H-McDonald-Associates-LLC/236883119983"><FontAwesomeIcon icon={faFacebookF} /></a>
				<a className="mx-1 btn btn-outline-primary border-0" href="https://twitter.com/bye_bye_debt"><FontAwesomeIcon className="mx-1" icon={faTwitter} /></a>
				<a className="ml-1 btn btn-outline-primary border-0" href="https://instagram.com/BANKRUPTCY_101"><FontAwesomeIcon className="mx-1" icon={faInstagram} /></a>
			</Col>
			<Col xs='7' className="text-sm-center"><FontAwesomeIcon className="mx-1" icon={faCalendarAlt} style={{height: '1rem'}} /> MON-FRI: 9am - 5pm</Col>
			<Col className="text-right"><FontAwesomeIcon className="mx-1" icon={faPhoneAlt} style={{height: '1rem'}} /> 205 982 3325</Col>
		</Row>
	</Container>
</section>